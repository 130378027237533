import { Card, Col } from "react-bootstrap";
import CardReferralLink from "./CardReferralLink.js";
import { Link } from "react-router-dom";
import {FormatCompanyForURL} from "./Sitemap.js";

const ReferralCard = ({ company, referralURL, description, children }) => {
  return (
    <Col md="6">
      <Card>
        <Card.Body>
          <Card.Title>
            <Link
              className="link-dark text-decoration-none"
              to={`/code/` + FormatCompanyForURL(company)}
            >
              {company}
            </Link>
          </Card.Title>
          <Card.Subtitle>
            <span className="d-block pb-3">{description}</span>
          </Card.Subtitle>
          <Card.Text>{children}</Card.Text>
          <h2 className="h6">
            <CardReferralLink href={referralURL}>
              {company} Referral Code
            </CardReferralLink>
          </h2>
        </Card.Body>
      </Card>
    </Col>
  );
};


export default ReferralCard;
