import {ReferralData} from "../data/ReferralData.js";

function BuildSitemap(root) {
    const data = ReferralData();
    const urls = [root];
    for (const k in data) {
        urls.push(root + "code/" + FormatCompanyForURL(data[k].Company));
    }

    return urls;
}
export function FormatCompanyForURL(name) {
    return name.replace(/[^a-zA-Z]+/gi, "-").toLowerCase()
}

export default BuildSitemap;
