import { Accordion, Container, Row } from "react-bootstrap";

const ReferralAccordianItem = ({ title, eventKey, children }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <strong>{title}</strong>
      </Accordion.Header>
      <Accordion.Body>
        <Container>
          <Row lg="3" className="gy-4">{children}</Row>
        </Container>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default ReferralAccordianItem;
