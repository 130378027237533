import { Helmet } from "react-helmet-async";
import App from "../components/App.js";
import { Fragment } from "react";

export default function Home() {
  return (
    <Fragment>
      <Helmet>
        <meta
          name="description"
          content="Referred by a friend. UK referral codes and invite codes. Sharing the love for my favourite brands."
        />
        <title>Referred by a friend - Referral codes</title>
      </Helmet>
      <App />
    </Fragment>
  );
}
