import { Container } from "react-bootstrap";

import "./Footer.css";

const Footer = () => (
    <Container>
      <span className="text-secondary">Sharing referral codes for services I use and enjoy.</span>
    </Container>
);

export default Footer;
