import { Link, Outlet } from "react-router-dom";
import Footer from "../components/Footer.js";
import { Container } from "react-bootstrap";


import "./Layout.css";

export default function Layout() {
  return (
    <>
      <main className="flex-shrink-0">
        <Container className="p-5 mb-4 bg-light rounded-3">
          <h1 className="header"><Link to="/" className="link-dark">Referred By A Friend</Link></h1>
          <h2 className="header">Let's be referral code friends ❤️</h2>
          <Outlet />
        </Container>
      </main>
      <footer className="footer mt-auto py-3 bg-light">
        <Footer />
      </footer>
    </>
  );
}
